<template>
    <header class="container-lg g-0">
        <div class="header-image-wrapper d-flex justify-content-center overflow-hidden">
            <div :style="{backgroundImage: `url('${image || defaultImage}')`}" class="header-image-blur"></div>
            <img :alt="title" :src="image || defaultImage" class="header-image">
            <h1 v-if="title.length > 0" class="page-title fancy-font">
                {{ title }}
            </h1>
        </div>
    </header>
</template>

<script>
export default {
  name: 'HeaderPage',
  components: {},
  props: {
    image: {required: false, type: String, default: null},
    title: {required: false, type: String, default: ''},
  },
  data () {
    return {
      defaultImage: require('@/assets/default.jpg')
    }
  },
}
</script>

<style scoped>
.page-title {
    text-shadow: 0 0 20px #000;
    z-index: 4;
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}
</style>
