<template>
    <div class="not-found-page">
        <HeaderPage :title="title"></HeaderPage>
        <main class="container-lg page-wrapper">
            <p class="text-center fst-italic p-3">Don't know how you get here, but there is nothing. Let me show you way
                to
                <router-link :to="{name: 'home'}" class="link-general">home</router-link>
                .
            </p>
        </main>
    </div>
</template>

<script>
import HeaderPage from '@/components/HeaderPage'

export default {
  name: 'NotFound',
  components: {HeaderPage},
  data () {
    return {
      title: '404 not found',
      videos: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.header-image-wrapper {
  margin: auto;
  position: relative;
  text-align: center;

  &-text {
    z-index: 4;
    font-size: 20px;
    padding-top: .5rem;
    color: #000;
    width: 100%;
    text-shadow: 0 0 5px #fff;
    position: absolute;
  }
}

.page-title {
  text-shadow: 0 0 20px #000;
  z-index: 4;
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
</style>
